import { locale } from 'expo-localization'

/**
 * Get the user's locale
 */
export function getUserLocale() {
  return !locale || locale === 'mock'
    ? 'en-US'
    : /* istanbul ignore next */ locale
}
