/**
 * Bezier curve configs that can be shared between Web, React Native and
 * Reanimated.
 */
export const beziers = {
  back: {
    out: [0.515, 1.9, 0.4, 0.92] as BezierConfig,
    in: [0.49, 0.19, 0.78, -1.03] as BezierConfig,
  },
  soft: {
    // Cubic
    out: [0.22, 0.61, 0.36, 1] as BezierConfig,
    in: [0.55, 0.055, 0.675, 0.19] as BezierConfig,
  },
  hard: {
    // Quart
    out: [0.17, 0.84, 0.44, 1] as BezierConfig,
    in: [0.895, 0.03, 0.685, 0.22] as BezierConfig,
  },
}

export type BezierConfig = [number, number, number, number]
type BezierList = typeof beziers

/**
 * Allows recursing deeper if the current T is an object but not a BezierConfig
 */
type RecursiveObject<T> = T extends BezierConfig
  ? never
  : T extends object
    ? T
    : never
/**
 * Change properties (nested, or not) OT (Old Type) to NT (New Type)
 */
type ToNewType<T, NT> = { [K in keyof T]: NT }
/**
 * Recursively convert BezierConfigs into the type passed.
 */
type NestedTypeChange<
  /**
   * The type with nested keys which we want to convert
   */
  T,
  /**
   * The old type that we want to replace.
   */
  OT,
  /**
   * The new type that we want the output type to use instead.
   */
  NT,
> = {
  [k in keyof T]: T[k] extends OT
    ? NT
    : T[k] extends RecursiveObject<T[k]>
      ? ToNewType<NestedTypeChange<T[k], OT, NT>, NT>
      : NT
}

/**
 * Generate a list of bezier easing curves for a given library. For example,
 * here is how you'd create easing curves for use with React Native Animated.
 * ```ts
 * import { Easing } from 'react-native'
 * const easings = bezierFactory((c) => Easing.bezier(...c))
 * ````
 */
export function bezierFactory<OutputType>(
  cb: (points: BezierConfig) => OutputType,
) {
  return Object.keys(beziers).reduce((acc, feel) => {
    if (!acc[feel]) {
      acc[feel] = Object.keys(beziers[feel]).reduce((acc2, direction) => {
        if (!acc2[direction]) {
          acc2[direction] = cb(beziers[feel][direction])
        }
        return acc2
      }, {})
    }
    return acc
  }, {}) as NestedTypeChange<BezierList, BezierConfig, OutputType>
}
