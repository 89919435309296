import { View, type ViewProps } from 'react-native'
import {
  opacity,
  type OpacityProps,
  visible,
  type VisibleProps,
  spacing,
  spacingShorthand,
  type SpacingProps,
  type SpacingShorthandProps,
  createRestyleComponent,
} from '@shopify/restyle'
import {
  shadows,
  type ShadowVariant,
  shadowColor,
  type ShadowColorProps,
  borderWidths,
  type BorderWidthProps,
  borderColor,
  type BorderColorProps,
  borderRadius,
  type BorderRadiusProps,
  borderStyle,
  type BorderStyleProps,
  backgroundColors,
  type BackgroundColorProps,
  layout,
  LayoutProps,
  dimensions,
  DimensionProps,
  position,
  PositionProps,
  positionLocation,
  PositionLocationProps,
  ZIndexProps,
  positionZ,
} from '~/theme'
import { AptTheme } from '~/theme'

export type BoxRestyleProps = OpacityProps<AptTheme> &
  VisibleProps<AptTheme> &
  SpacingProps<AptTheme> &
  SpacingShorthandProps<AptTheme> &
  PositionProps &
  PositionLocationProps &
  ZIndexProps &
  LayoutProps &
  DimensionProps &
  ShadowVariant &
  ShadowColorProps &
  BackgroundColorProps &
  BorderWidthProps &
  BorderColorProps &
  BorderRadiusProps &
  BorderStyleProps &
  BorderColorProps

export const boxRestyleFunctions = [
  opacity,
  visible,
  layout,
  dimensions,
  spacing,
  spacingShorthand,
  position,
  positionLocation,
  positionZ,
  shadows,
  shadowColor,
  backgroundColors,
  borderWidths,
  borderColor,
  borderRadius,
  borderStyle,
]

export type BoxProps = ViewProps & BoxRestyleProps
export type BoxRef = React.Ref<View>

// Documentation should go below
export const Box = createRestyleComponent<BoxProps, AptTheme>(
  boxRestyleFunctions,
  View,
)

/**
 * `Box2` is a primitive layout component that is used to wrap other components.
 * On web it will render a `<div>` and on native a `<View>`.
 *
 * `Box2` is derived from `@shopify/restyle`, giving you styling props connected
 * to our theme. You can override styles or use styles that aren't defined in
 * our theme by using the standard `style` prop.
 *
 * ```jsx
 * <Box
 *   // This will use the color called `primary.200` in our theme.
 *   // See `~/theme/themes/light/palettes`
 *   backgroundColor="primary.200"
 *   // This will use the margin size "s" from our theme.
 *   margin="s"
 *   // This will set the padding responsively to the screen dimensions
 *   p={{phone: 'm', tablet: 'l'}}
 *   // Anything in the style prop will override the attribute styles.
 *   // You can also specify values that don't exist in our theme.
 *   style={{
 *     backgroundColor: '#ff00ff',
 *   }}
 * />
 * ```
 *
 * For more details, check out the [Restyle
 * docs](https://shopify.github.io/restyle/fundamentals/restyle-functions).
 *
 * > Note that we have customized some of the Restyle functions slightly for our
 * > needs:
 * > - `backgroundColor` combines the `colors` and `backgroundColors` theme keys
 * > - `foregroundColor` combines the `colors` and `foregroundColors` theme keys
 * > - `borderColor` matches `foregroundColor`
 * > - `borderWidth` uses the `spacing` key from our theme
 * > - `width/height` use the `spacing` theme keys but fallback to the value passed
 * > - `boxShadow` pulls from the `shadows` key in the theme
 * > - `shadowColor` matches `backgroundColor`
 * > - `textShadowColor` matches `foregroundColor`
 */
export const BoxStorybook: React.FC<BoxProps> = Box
