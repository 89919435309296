import React from 'react'
import { useTimeout } from '@thesoulfresh/react-tools'

/**
 * Returns a function that you can call to delay a promise returning callback.
 * It has the same API as `setTimeout` except that it returns a Promise that
 * resolves when your callback resolves. It will also delay execution of your
 * callback for the delay time specified. This function also has the advantage
 * that your callback will only be called if the wrapping component is still
 * mounted.
 *
 * Example Usage:
 *
 * ```js
 * function MyComponent() {
 *   const wait = useDelayPromise()
 *   const [loading, setLoading] = React.useState(false)
 *
 *   React.useEffect(() => {
 *     // The following API call will be made 60ms after this effect runs.
 *     // If the component is unmounted before the timeout, then the callback
 *     // will not be called.
 *     wait(() => {
 *       return api.getSomeStuff()
 *         .then(() => setLoading(false))
 *         .catch(() => setLoading(false))
 *     }, 60)
 *   }, [])
 * }
 * ```
 */
export function useDelayPromise(
  /**
   * Ignore the timeout setting. This is useful for disabling timeouts during
   * testing.
   */
  skipTimeout = false,
) {
  const wait = useTimeout()
  return React.useCallback(
    (cb, ms?: number) =>
      new Promise((resolve, reject) => {
        if (skipTimeout) {
          cb().then(resolve).catch(reject)
        } else {
          // TODO There's probably a way to type this so the cb params are
          // correctly inferred.
          wait((...args: unknown[]) => {
            cb(...args)
              .then(resolve)
              .catch(reject)
          }, ms)
        }
      }),
    [wait, skipTimeout],
  )
}
