import type { AuthUser } from './AuthService'

export interface Auth0Claims {
  'x-hasura-auth0-user-id': string
  'x-hasura-default-role': string
  'x-hasura-allowed-roles': string[]
  'x-hasura-email': string
  'x-hasura-title': string
  'x-hasura-locale': string
  'x-hasura-created-at': string
  'x-hasura-date-of-hire': string
  'x-hasura-persona-name': string
  'x-hasura-aptsnap-user-id': string
  'x-hasura-ownership-group-id': string
  'x-hasura-ownership-group-name': string
  'x-hasura-ownership-group-created-at': string
  'x-hasura-ownership-group-start-date': string
  'x-hasura-ownership-group-finalized-date': string
  'x-hasura-ownership-group-effective-date': string
}

export interface Auth0Response {
  id: string
  email: string
  email_verified: boolean
  name: string
  nickname: string
  picture: string
  updated_at: string
  'https://hasura.io/jwt/claims': Auth0Claims
}

export const fromAuth0 = {
  user: (u: Auth0Response = {} as Auth0Response) => {
    const user = {
      id: u.id,
      email: u.email,
      emailVerified: u.email_verified,
      name: u.name,
      nickname: u.nickname,
      picture: u.picture,
      updatedAt: u.updated_at,
    } as AuthUser

    // Make the custom Hasura claims more easily available on the user object.
    const claims = u['https://hasura.io/jwt/claims']
    if (claims) {
      user.id = claims['x-hasura-aptsnap-user-id']
      user.defaultRole = claims['x-hasura-default-role']
      user.allowedRoles = claims['x-hasura-allowed-roles']
      user.title = claims['x-hasura-title']
      user.locale = claims['x-hasura-locale']
      user.createdAt = claims['x-hasura-created-at']
      user.dateOfHire = claims['x-hasura-date-of-hire']
      user.personaName = claims['x-hasura-persona-name']
      user.ownershipGroup = {
        id: claims['x-hasura-ownership-group-id'],
        name: claims['x-hasura-ownership-group-name'],
        createdAt: claims['x-hasura-ownership-group-created-at'],
        defaultStartDate: claims['x-hasura-ownership-group-start-date']
          ? Number(claims['x-hasura-ownership-group-start-date'])
          : undefined,
        finalizedDate: claims['x-hasura-ownership-group-finalized-date']
          ? Number(claims['x-hasura-ownership-group-finalized-date'])
          : undefined,
        effectiveDate: claims['x-hasura-ownership-group-effective-date'],
      }
    }

    return user as AuthUser
  },
}
