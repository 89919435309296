import { MTLB } from './constants'

type Bag = { [key: string]: any }

/**
 * Coerce a string representing some other type into that type.  For example,
 * 'true' and 'false' will be converted to booleans. Anything that can be
 * converted to a Number, will be (unless the property name ends with "Id" such
 * as "propertId"). Anything surrounded in '{}' will be JSON parsed. Anything
 * surrounded in '[]' will be converted into an array with it's elements run
 * through this function.
 */
export function coerceStringToType(value: string, key = '') {
  // Array handling
  if (value.startsWith('[') && value.endsWith(']')) {
    try {
      return (
        value
          // Remove the outer array brackets
          .slice(1, -1)
          // Split into an array
          .split(',')
          // Coerce each element in the array
          .map((v) => coerceStringToType(v.trim()))
      )
    } catch (e) {
      console.warn('Unable to parse array query parameter:', e)
      return value
    }
  }
  // Objects
  else if (
    value.startsWith('{') &&
    value.endsWith('}')
  ) {
    try {
      return JSON.parse(decodeURI(value).replace(/'/g, '"'))
    } catch (e) {
      console.warn('Unable to parse object query parameters:', e)
      return value
    }
  }
  // prettier-ignore
  else if (
    (value.startsWith('"') && value.endsWith('"')) ||
    (value.startsWith("'") && value.endsWith("'"))
  ) {
    return value.slice(1, -1)
  }
  // Booleans
  else if (value === 'true') {
    return true
  } else if (value === 'false') {
    return false
  }
  // Numbers
  else if (!isNaN(Number(value)) && !key.endsWith('Id')) {
    return Number(value)
  } else {
    return value
  }
}

/**
 * Parse an object of key value pairs where values should be coerced from
 * strings to other types. This is useful for converting query parameters from
 * string values into numbers, objects, arrays, booleans.
 */
export function parseQueryParams(
  /**
   * An object with values that should be coerced from strings
   */
  params: Bag,
  /**
   * An array of properties from `params` that should not be coerced.
   */
  blacklist: string[] = [],
) {
  if (!params) return params

  return Object.keys(params).reduce((acc, key) => {
    const value = params[key]
    acc[key] = blacklist.includes(key)
      ? value
      : Array.isArray(value)
        ? value.map((v) => coerceStringToType(v, key))
        : coerceStringToType(value, key)
    return acc
  }, {})
}

/**
 * Convert a `URLSearchParams` object into a plain JS object of key/values.
 */
export function urlSearchParamsToObject(params: URLSearchParams) {
  const out = Array.from(params).reduce((acc, [key, value]) => {
    // If the key already exists, then convert the value into an array
    if (acc[key]) {
      acc[key] = [].concat(acc[key], value)
    } else {
      acc[key] = value
    }
    return acc
  }, {})
  return out
}

/**
 * Create a `mailto:` link that works on all systems. While creating a `mailto:`
 * link seems pretty simple, there are a few subtleties when trying to add
 * multiple lines of text to the email body and getting that to work on web and
 * native.
 */
export function mailToLink(
  /**
   * The email you are sending to.
   */
  email: string,
  /**
   * The email subject.
   */
  subject?: string,
  /**
   * The email body text. If you pass an array, then each item is treated as a
   * separate line. You can add extra new lines by inserting empty strings (ex
   * `['foo', '', 'bar']` gives an empty line between "foo" and "bar").
   */
  body?: string | string[],
) {
  const params = [
    ['subject', subject],
    // Combine body lines into a single string
    ['body', Array.isArray(body) ? body.join(MTLB) : body],
  ]
    // Filter any parameters that don't have a value
    .filter((p) => !!p[1])
    // Join the inner string pairs with =
    .map((p) => p.join('='))
    // Join the parameters into a single string
    .join('&')

  return encodeURI(
    [`mailto:${email}`, params]
      // Filter out the parameters if there are none
      .filter((v) => !!v)
      // And combine into a single URL string
      .join('?'),
  )
}
