import { bezierFactory } from './beziers'

const animation = {
  spring: {
    stiff: {
      stiffness: 130,
      mass: 1,
      damping: 15,
    },
  },
  easing: {
    linear: 'linear',
    ...bezierFactory((c) => `cubic-bezier(${c.join(',')})`),
  },
  timing: {
    // All times in ms
    xl: 1000,
    l: 750,
    m: 500,
    s: 250,
    xs: 150,
  },
}

type ThemeAnimation = typeof animation
type Easing = ThemeAnimation['easing']
type ThemeEasingGroup = keyof Easing
type ThemeEasingDirection = keyof Easing[ThemeEasingGroup]
export type ThemeEasing =
  | `${ThemeEasingGroup}.${ThemeEasingDirection}`
  | 'linear'

export default animation
