import React from 'react'
import { SegmentEvent } from '@segment/analytics-next'

import { makeLogger } from '~/utils'
import { Segment } from './mocks'

const AnalyticsContext = React.createContext(undefined)
export const AnalyticsProvider = ({ children, client }) => {
  return <AnalyticsContext.Provider value={client} children={children} />
}

const logger = makeLogger('AnalyticsService', '#f44c7f')
export const analyticsLogger = logger

const info = (event: SegmentEvent) => {
  logger.groupCollapsed(
    `(${event.type.toUpperCase()})`,
    event.event || event.context.page.title,
  )
  logger.info('data:', event.traits || event.properties)
  logger.info('event context:', event.context)
  logger.info('full event:', event)
  logger.groupEnd()
  return event
}

const identify = (event: SegmentEvent) => {
  logger.groupCollapsed(
    `(${event.type.toUpperCase()})`,
    event.traits?.name || event.properties?.name,
  )
  logger.info('data:', event.traits || event.properties)
  logger.info('event context:', event.context)
  logger.info('full event:', event)
  logger.groupEnd()
  return event
}

export const loggerMiddleware = ({ payload, next }) => {
  const data = payload.obj
  switch (data.type) {
    case 'identify':
    case 'group':
    case 'alias':
      identify(data)
      break
    default:
      info(data)
      break
  }
  next(payload)
}

interface Config {
  writeKey: string
  debug?: boolean
  logger?: any
}

type SegmentClient = ReturnType<typeof Segment.createClient>

type WebAndNativeSegmentClient = SegmentClient & {
  page: SegmentClient['screen']
  addSourceMiddleware: (...args: any[]) => void
}

type Win = Window &
  typeof globalThis & {
    analytics: WebAndNativeSegmentClient
  }

/**
 * Create an anlytics client to be used throughout the application.
 * This reproduces the same API as the native client.
 */
let analytics: WebAndNativeSegmentClient

const win = window as Win

export function createClient(options: Config) {
  if (analytics) {
    return analytics
  }

  if (options.debug) {
    win.analytics.addSourceMiddleware(loggerMiddleware)
  }

  // If you need really verbose logging, turn this on:
  // win.analytics.debug(true)

  analytics = {
    identify: (...args: Parameters<SegmentClient['identify']>) =>
      win.analytics.identify(...args),
    group: (...args: Parameters<SegmentClient['group']>): any =>
      win.analytics.group(...args),
    alias: (...args: Parameters<SegmentClient['alias']>) =>
      win.analytics.alias(...args),
    track: (...args: Parameters<SegmentClient['track']>) =>
      win.analytics.track(...args),
    page: (...args: Parameters<SegmentClient['screen']>) =>
      win.analytics.page(...args),
    // Forward `screen` to `page` for compatibility with native.
    screen: (...args: Parameters<SegmentClient['screen']>) =>
      win.analytics.page(...args),
  } as WebAndNativeSegmentClient
  return analytics
}

export function useSegmentAnalytics() {
  return React.useContext(AnalyticsContext)
}
