import React from 'react'
import { LayoutRectangle, Platform } from 'react-native'
import * as SplashScreen from 'expo-splash-screen'

import {
  Box,
  Splash,
  ActionBase,
  ErrorView,
  BodyText,
  BoxProps,
  StyleTransition,
} from '~/components'
import { DarkThemeProvider } from '~/theme'
import { env } from '~/env'
import { getPageOrigin, useAnalytics } from '~/services/analytics'

const NO_USER_MESSAGE =
  'Oh No! It looks like we were unable to load your account. ' +
  `Please contact ${env.supportEmail} so we can help complete your account creation.`

interface LoginProps extends BoxProps {
  onLogin: () => Promise<any>
  ready: boolean
  loading: boolean
  authenticated: boolean
  userNotFound?: boolean
  loginError?: boolean
  animationDelay?: number
  animationDuration?: number
  disableSplashAnimation?: boolean
}

/**
 * `<Login>` page shows the Splash screen and a login button.
 * The actual login form is displayed in a browser window after
 * clicking the login button. That form is hosted by Auth0 and
 * can be configured through the Auth0 console.
 */
export const Login = ({
  onLogin,
  ready,
  loading,
  authenticated,
  userNotFound = false,
  loginError,
  animationDelay = 250,
  animationDuration = 250,
  disableSplashAnimation,
  ...rest
}: LoginProps) => {
  const emptyDimensions: LayoutRectangle = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  }
  const [dimensions, setDimensions] = React.useState(emptyDimensions)

  const showButton = ready && !authenticated
  const enableButton = ready && !loading

  React.useEffect(() => {
    /* istanbul ignore next: this is really just to prevent error logs */
    SplashScreen.hideAsync().catch(() => {
      // Ignore errors hiding the splash screen. These occur when
      // we hide and show the login multiple times in a session.
    })
  }, [])

  const w = dimensions.width || 0
  const h = dimensions.height || 0
  const diameter = Math.sqrt(w * w + h * h)
  const top = h / 2 - diameter / 2
  const left = w / 2 - diameter / 2

  return (
    <Box
      testID="Login"
      width="100%"
      height="100%"
      flex={1}
      position="relative"
      onLayout={(e) => setDimensions(e.nativeEvent.layout)}
      {...rest}
    >
      <Splash
        position="absolute"
        width={diameter}
        height={diameter}
        style={{
          borderRadius: (diameter || /*istanbul ignore next*/ 1) / 2,
          transform: [{ translateY: top }, { translateX: left }],
        }}
        state={
          /* istanbul ignore next: Animation loop causes issues during testing */
          disableSplashAnimation ? 'stopped' : loading ? 'playing' : 'stopped'
        }
        animationDuration={
          disableSplashAnimation ? 0 : /*istanbul ignore next*/ undefined
        }
        logoPosition={userNotFound ? -100 : '35%'}
      />
      <StyleTransition
        state={showButton}
        animateEntrance={false}
        persistent={false}
        disableAnimations={disableSplashAnimation}
        transitions={[
          {
            // We use two different opacity transitions here because we want to
            // transition between 3 states (hidden, disabled, enabled) but
            // StyleTransition only supports 2 state transitions.
            // TODO Update StyleTransition to handle multiple states
            property: 'opacity',
            duration: animationDuration,
          },
        ]}
        style={{
          position: 'absolute',
          top: '60%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <StyleTransition
          testID="ButtonWrapper"
          state={enableButton}
          disableAnimations={disableSplashAnimation}
          transitions={[
            {
              property: 'opacity',
              duration: animationDuration,
              value: (p) => {
                'worklet'
                return 0.5 + p * 0.5
              },
            },
          ]}
          style={{
            alignItems: 'center',
          }}
        >
          {/* Use an ActionBase because the router is not available yet */}
          <ActionBase
            testID="LoginButton"
            disabled={loading || authenticated}
            width={250}
            onPress={() => onLogin()}
          >
            Log In
          </ActionBase>
          {loginError && (
            <DarkThemeProvider>
              <BodyText mt="s">
                Hmmm...something went wrong trying to log you in. Please give it
                another try.
              </BodyText>
            </DarkThemeProvider>
          )}
        </StyleTransition>
      </StyleTransition>
      {userNotFound && (
        <ErrorView
          title="User Not Found"
          message={NO_USER_MESSAGE}
          showHelp={false}
          dark
          flex={1}
          width="100%"
          height="100%"
          alignItems="center"
          justifyContent="center"
        />
      )}
    </Box>
  )
}

export interface LoginConnectedProps extends LoginProps {
  /**
   * Allows configuring the global Document object during testing.
   */
  doc?: Document
}

/**
 * `<LoginConnected>` connects the Login
 * component with the rest of the app (ie. routing, services, store, etc.).
 */
export const LoginConnected = ({
  onLogin,
  ready,
  loading,
  authenticated,
  userNotFound,
  animationDelay,
  animationDuration,
  disableSplashAnimation,
  doc = window.document,
  ...rest
}: LoginConnectedProps) => {
  const origin = getPageOrigin()
  const { screen, track } = useAnalytics()

  const [loginError, setLoginError] = React.useState(false)
  const handleLogin = async () => {
    try {
      track('Click', {
        category: 'Splash Screen',
        action: 'Log In',
        label: 'Log In',
        path: '/',
        url: `${origin}/`,
      })
      await onLogin()
    } catch (e) {
      // This is only intended to catch unexpected errors caused by bugs as
      // opposed to authentication falures such as Auth0 service being down or
      // returning an error code.
      console.error('[Login] an error occured during login:', e)
      setLoginError(true)
    }
  }

  React.useEffect(() => {
    if (!ready && loading) {
      screen('Splash Screen', {
        component: 'LoginConnected',
        path: '/',
        url: `${origin}/`,
        referrer: Platform.OS === 'web' ? doc?.referrer : '',
        title: 'Splash Screen',
        search: '',
      })
    }
  }, [ready, loading, doc?.referrer, origin, screen])

  React.useEffect(() => {
    if (ready && !loading && userNotFound) {
      screen('User Not Found', {
        component: 'LoginConnected',
        path: '/user-not-found',
        url: `${origin}/user-not-found`,
        referrer: `${origin}/login`,
        title: 'User Not Found',
        search: '',
      })
    }
  }, [ready, userNotFound, loading, origin, screen])

  React.useEffect(() => {
    if (ready && !loading && !userNotFound && !authenticated && !loginError) {
      screen('Log In', {
        component: 'LoginConnected',
        path: '/login',
        url: `${origin}/login`,
        referrer: `${origin}/`,
        title: 'Log In',
        search: '',
      })
    }
  }, [ready, userNotFound, loading, authenticated, loginError, origin, screen])

  return (
    <Login
      onLogin={handleLogin}
      ready={ready}
      loading={loading}
      authenticated={authenticated}
      userNotFound={userNotFound}
      loginError={loginError}
      animationDuration={animationDuration}
      animationDelay={animationDelay}
      disableSplashAnimation={disableSplashAnimation}
      {...rest}
    />
  )
}
