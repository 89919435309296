type ListOfGenerateCallback<T> = (i: number, total: number) => T

/**
 * Type Guard and filter to remove `undefined | null` values from an array.
 *
 * @example
 *     ['bar', 'foo', undefined, null]
 *       // Remove nulls
 *       .filter(removeNulls)
 *       // a & b are guaranteed to exist and Typscript gets it!
 *       .sort((a, b) => { ... })
 */
export const removeNulls = <S>(value: S | undefined): value is S =>
  value != null

/**
 * Generate a list of items, calling a factory function
 * to generate each item.
 * @param [count] - The number of items to generate.
 * @param [generate] - The factory used to create
 *   the item. It will be called with the current index in the list.
 *   If the factory is not passed, then the index is returned as
 *   the generated item.
 * @return The list of generated items.
 */
export function listOf<T>(
  count = 1,
  generate: ListOfGenerateCallback<T> = (i: number) => i as T,
): T[] {
  const out = []
  for (let i = 0; i < count; i++) {
    out.push(generate(i, count))
  }
  return out
}
