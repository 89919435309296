import React from 'react'
import { Platform } from 'react-native'
import { useTimeout } from '@thesoulfresh/react-tools'

import appJSON from '~/../app.json'
import {
  analyticsLogger as logger,
  createClient,
  SegmentClient,
  useSegmentAnalytics,
} from './segment'
import { env } from '~/env'
import { getRoute } from '~/routes'
import { useLocation } from '~/navigation'
import { User } from '../report-api'
import { normalizeToDateObject } from '~/utils'
import { useIsFirstRender } from '~/hooks/useIsFirstRender'

export type AnalyticsService = ReturnType<typeof createClient>

/* istanbul ignore next: only enabled outside of the test env */
function debugLog(
  type: string,
  summary: string,
  args: Record<string, string | number>,
) {
  if (!env.test && env.verbose) {
    console.groupCollapsed(
      `%c[AnalyticsService] %c${type} %c${summary} %c(tracking disabled)`,
      'color: #f44c7f; font-weight: bold;',
      'color: initial; font-weight: bold;',
      'color: #f44c7f; font-weight: bold;',
      'color: #aaa; font-weight: normal;',
    )
    for (const prop in args) {
      console.log(
        `%c${prop}: %c${args[prop]}`,
        'color: #aaa, font-weight: bold',
        'color: initial; font-weight: normal;',
      )
    }
    console.groupEnd()
  }
}

/**
 * Create a custom useAnalytics hook so we can add default properties to all
 * events.
 */
const defaultAnalyticsProperties = {
  jsVersion: env.jsVersion,
  appVersion: env.appVersion,
}

export const useAnalytics = () => {
  const analytics = useSegmentAnalytics()

  function MakeTracker(
    type: string,
    ana: ReturnType<typeof useSegmentAnalytics>,
  ) {
    return React.useCallback(
      (event: string, properties = {}) => {
        ana[type](event, {
          platform: getPlatform(),
          ...defaultAnalyticsProperties,
          ...properties,
        })
      },
      [type, ana],
    )
  }

  return {
    track: MakeTracker('track', analytics),
    screen: MakeTracker('screen', analytics),
    // Allow using `page` as an alias for `screen`
    page: MakeTracker('screen', analytics),
    identify: MakeTracker('identify', analytics),
    group: MakeTracker('group', analytics),
    alias: MakeTracker('alias', analytics),
    reset: MakeTracker('reset', analytics),
  }
}

let clientCount = 0
/**
 * Generate the analytics client to use for the app.
 *
 * ie.
 *  const analyticsService = useAnalyticsService()
 *  analyticsService.screen('Login Page', {
 *    component: 'LastLineOfDefence',
 *    path: getPageOrigin(),
 *    platform: getPlatform(),
 *    ...etc
 *  })
 *
 *  More info on the different specs can be found here:
 *  https://segment.com/docs/connections/spec/
 */
export function useAnalyticsService(
  client?: AnalyticsService,
  enabled = env.analyticsEnabled,
) {
  return React.useMemo(() => {
    if (client) {
      return client
    } else if (enabled) {
      clientCount += 1
      /* istanbul ignore next */
      if (env.verbose)
        logger.info('Creating AnalyticsService with key', env.segmentWriteKey)
      /* istanbul ignore next */
      if (clientCount > 1) {
        logger.error(`Created AnalyticsService ${clientCount} times`)
      }

      return createClient({
        writeKey: env.segmentWriteKey,
        debug: env.verbose,
      })
    } else {
      /* istanbul ignore next */
      if (env.verbose) logger.info('Tracking disabled')
      // If analytics is disabled, provide a stub implementation so we don't
      // throw errors trying to access the analytics client.
      // prettier-ignore
      return {
        track:    /* istanbul ignore next */ (event: string, args: any) => debugLog(`TRACK`, event, args),
        screen:   /* istanbul ignore next */ (title: string, args: any) => debugLog('SCREEN', title, args),
        identify: /* istanbul ignore next */ (_id: string, args: any) => debugLog('IDENTIFY', args.name, args),
        group:    /* istanbul ignore next */ (_id: string, args: any) => debugLog('GROUP', args.name, args),
        alias:    /* istanbul ignore next */ (args: any) => debugLog('ALIAS', args.name, args),
        reset:    /* istanbul ignore next */ (args: any) => debugLog('RESET', '', args),
      } as any as SegmentClient
    }
  }, [client, enabled])
}

/**
 * Mimic the native "Application Opened/Backgrounded" events on web.
 */
export function useTrackBrowserFocus(
  /**
   * The amount of time that the browser must retain focus in order to be
   * considered an "Open" event. This ensures that if the user unintentionally
   * focuses the browser (for example, while switching desktops or apps), that
   * we don't send false open events.
   */
  focusDelay = 5000,
  /**
   * TESTING ONLY
   * Allows passing the document object in the test environment
   */
  doc: Document | undefined = window.document,
) {
  const { track } = useAnalytics()
  const firstRender = useIsFirstRender()
  const wait = useTimeout()

  React.useEffect(() => {
    if (Platform.OS === 'web') {
      let timeout: number

      // Application focused events don't occur on first load so we'll mimic the
      // initial load here.
      if (firstRender) {
        track('Application Opened', {
          from_background: !firstRender,
        })
      }

      const onStateChange = () => {
        if (doc?.visibilityState === 'visible') {
          timeout = wait(() => {
            timeout = null
            track('Application Opened', {
              from_background: !firstRender,
            })
          }, focusDelay)
        }
        // Browser Blur
        else {
          // If we lose focus before the timeout, then don't consider this an
          // open/close and instead ignore both events.
          if (timeout) {
            // This will prevent the application opened event from firing.
            clearTimeout(timeout)
          }
          // If there is no timeout, then we already sent an opened event and we
          // should send the closed event.
          else {
            track('Application Backgrounded')
          }
        }
      }

      doc?.addEventListener('visibilitychange', onStateChange)
      return () => doc?.removeEventListener('visibilitychange', onStateChange)
    }
  })
}

/**
 * Associate the current viewing session with a user. This call should only be
 * made once a session, and should be made as soon as the user is known.
 */
export function useTrackUser(user: User) {
  const { identify, group } = useAnalytics()

  React.useEffect(() => {
    if (user) {
      // Join plan names in case there are more than one (unlikely).
      const planNames = user.accessibleFeatures.map((f) => f.planName)
      const plans = Array.from(new Set(planNames)).join(', ') //Removes dups

      const company = {
        id: user.ownershipGroup.id,
        name: user.ownershipGroup.name,
        plan: plans || 'basic',
        industry: 'real estate',
        createdAt: normalizeToDateObject(
          user.ownershipGroup.createdAt,
        ).toISOString(),
      }

      // Identify the user
      identify(user.id, {
        email: user.email,
        name: user.name,
        nickname: user.nickname,
        createdAt: user.createdAt,
        roles: user.allowedRoles,
        title: user.title,
        locale: user.locale,
        avatar: user.picture,
        company: company,
        dateOfHire: user.dateOfHire,
        personaName: user.personaName,
      })
      // Associate the user with their ownership group
      group(company.id, company)
    }
  }, [user, identify, group])
}

/**
 * Get the URL origin for the current page. This will be something like
 * "http://localhost:19006" or "https://apartmentsnapshot.com" on web and
 * "apartmentsnapshot://" on native.
 */
export function getPageOrigin() {
  const webOrigin = window?.location?.origin
  return Platform.OS === 'web'
    ? webOrigin
      ? webOrigin
      : ''
    : `${appJSON.expo.scheme}://`
}

/**
 * Get the app type for the current session. This will only ever be "web" or
 * "native" and helps us differentiate between the two. The analytics tool will
 * track ios vs android independently.
 */
export function getPlatform() {
  return Platform.OS === 'web' ? 'web' : 'native'
}

const trimTrailingSlash = (str: string) => str.replace(/\/$/, '')

/**
 * Get a fully qualified URL for the current page. For native devices, this will
 * be prefixed with the app's scheme (e.g. "apartmentsnapshot://").
 */
export function usePageURL() {
  const location = useLocation()
  const origin = getPageOrigin()
  // Sometimes redirects leave a trailing slash on the path. For consistency,
  // we ensure the trailing slash is always removed.
  const path =
    trimTrailingSlash(location.pathname) + location.hash + location.search
  const url = `${origin}${path}`
  return {
    origin,
    path,
    url,
    search: location.search,
  }
}

/**
 * Track a page view.
 */
export function useTrackPage(
  /**
   * The route identifier from the routes file. This is the same ID passed to
   * `getURL`
   */
  routeName: string,
  /**
   * The name of the component being tracked (ex. 'LoginConnected'). You can use
   * `~/utils#getComponentName` to get this value if you are able to pass the
   * component JSX to the function.
   */
  componentName: string,
) {
  const route = getRoute(routeName)
  const { screen } = useAnalytics()
  const { path, url, search } = usePageURL()
  const analyticsId = route?.analyticsId

  React.useEffect(() => {
    if (!analyticsId) {
      /* istanbul ignore next */
      logger.error(`No tracking id configured for page ${componentName}`)
      return
    }

    screen(analyticsId, {
      component: componentName,
      path,
      url,
      search,
      referrer: '',
      title: route.title,
    })
  }, [screen, path, url, analyticsId, componentName, route?.title, search])
}
