import React from 'react'
import { GestureResponderEvent } from 'react-native'
import { ActionText } from './ActionText'
import { Gradient } from '../../gradients'
import { Button, type ButtonProps, type ButtonRef } from './Button'
import {
  RoutedLink,
  type RoutedLinkProps,
  type RoutedLinkRef,
} from './RoutedLink'
import {
  ExternalLink,
  type ExternalLinkProps,
  type ExternalLinkRef,
} from './ExternalLink'

type RoutedLinkType = Omit<RoutedLinkProps, 'hasChildren'> & { blank?: never }
type ExternalLinkType = Omit<ExternalLinkProps, 'hasChildren'>
type ButtonType = Omit<ButtonProps, 'hasChildren'> & { blank?: never }

/**
 * A simplified version of `ActionBaseProps` for cases where it's hard for
 * Typescript to determine the type you intend. This is especially useful for
 * Storybook and components that extend `Action` by wrapping their contents in an
 * Action and passing a `rest` prop. In this version, required or never props
 * are all optional.
 */
export type LooseActionBaseProps = (
  | Omit<RoutedLinkProps, 'href' | 'onPress' | 'hasChildren'>
  | Omit<ExternalLinkProps, 'href' | 'onPress' | 'hasChildren'>
  | Omit<ButtonProps, 'onPress' | 'hasChildren'>
) & {
  href?: string
  blank?: boolean
  onPress?: (e: GestureResponderEvent) => void
}

export type ActionBaseProps = RoutedLinkType | ExternalLinkType | ButtonType

export type ActionBaseRef = ButtonRef | RoutedLinkRef | ExternalLinkRef

/**
 * A basic link/button component without analytics integration. Generally you
 * should prefer using `Action` which includes analytics tracking.
 */
export const ActionBase = React.forwardRef<ActionBaseRef, ActionBaseProps>(
  (
    {
      href,
      blank,
      look: lookProp,
      onPress,
      disabled,
      feel,
      transparent,
      position,
      icon,
      iconPosition = 'start',
      children,
      ...rest
    },
    ref,
  ) => {
    const isLink = !!href
    const look = lookProp || (isLink ? 'link' : 'button')
    const showGradient = look === 'button' && feel === 'primary'
    const hasChildren = !!children

    // Props used by the DefaultText and Action
    const sharedProps = {
      look,
      feel,
      disabled,
      transparent,
      position: showGradient ? 'relative' : position,
      icon,
      iconPosition,
    }

    const buttonProps = {
      onPress: (e: GestureResponderEvent) => {
        if (onPress && !disabled) {
          onPress(e)
        }
      },
      hasChildren,
      ...sharedProps,
      ...rest,
    }

    const textProps = {
      ...sharedProps,
    }

    if (typeof children === 'string') {
      children = <ActionText {...textProps}>{children}</ActionText>
    }

    if (icon) {
      children = (
        <>
          {iconPosition !== 'end' &&
            React.cloneElement(icon, { mr: hasChildren ? 'xs' : 0 })}
          {children}
          {iconPosition === 'end' &&
            React.cloneElement(icon, { ml: hasChildren ? 'xs' : 0 })}
        </>
      )
    }

    if (showGradient) {
      children = (
        <>
          <Gradient
            position="absolute"
            top={0}
            bottom={0}
            left={0}
            right={0}
            zIndex="below"
          />
          {children}
        </>
      )
    }

    if (isLink) {
      if (blank) {
        return (
          <ExternalLink
            href={href}
            blank={blank}
            children={children}
            {...buttonProps}
            ref={ref}
          />
        )
      } else {
        return (
          <RoutedLink
            href={href}
            children={children}
            {...buttonProps}
            ref={ref}
          />
        )
      }
    } else {
      return <Button children={children} {...buttonProps} ref={ref} />
    }
  },
)
